import datePickerLocale from "baseui/datepicker/locale"
import fileUploadLocale from "baseui/file-uploader/locale"
import selectLocale from "baseui/select/locale"
import { Locale } from "baseui/locale"

const localeKo: Partial<Locale> = {
  datepicker: {
    ...datePickerLocale,
    quickSelectLabel: "조회할 기간 선택",
  },
  fileuploader: {
    ...fileUploadLocale,
    browseFiles: "파일 탐색",
    dropFilesToUpload: "업로드할 파일을 여기에 놓으세요...",
  },
  select: {
    ...selectLocale,
    placeholder: "선택...",
  },
}

export default localeKo
