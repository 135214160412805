import { ToastMessage } from "../../api/toast"

export function formatToastMessageResult(message: ToastMessage): string {
  switch (message.resendStatus) {
    case "RSC01": // 대체 발송 미대상
    case "RSC02": // 대체 발송 대상 (발송 결과 실패 시, 대체 발송이 진행됩니다.)
      if (message.resultCode === "1000") {
        return "알림톡 발송 성공"
      } else {
        return `알림톡 발송 실패 (발송 실패 사유: ${message.resultCodeName})`
      }

    case "RSC03": // 대체 발송 중
      return message.resendStatusName

    case "RSC04": // 대체 발송 성공
      return `${message.resendStatusName} (대체 발송 사유: ${message.resultCodeName})`

    case "RSC05": // 대체 발송 실패
      return `${message.resendStatusName} (발송 실패 사유: ${message.resultCodeName})`

    default:
      return "알림톡 발송 결과를 알 수 없습니다. 개발팀에 문의 해주세요."
  }
}
