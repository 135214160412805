import { Cart } from "@today/api/tracker"

export function getCartRoleName(state: Cart["role"]) {
  switch (state) {
    case "NO_ROLE":
      return "용도 없음"
    case "PICK_UP":
      return "픽업 카트"
    case "SHIPPING":
      return "운송 카트"
    default:
      return state
  }
}
