import { Order } from "@today/api/taker"
import { Load } from "@today/api/tracker"

export function formatShippingType(
  shippingType: Order["shippingType"] | Load["shippingType"]
): string {
  switch (shippingType) {
    case "LM_TO_LM":
      return "LM → LM"
    case "LM_TO_STATION":
      return "LM → 출고지"
    case "STATION_TO_LM":
      return "출고지 → LM"
    case "STATION_TO_STATION":
      return "출고지 → 출고지"
  }
}
