import { AlimtalkEventType, SenderOrReceiver } from "@today/api/tracker"

export function getAlimtalkEventName(event: AlimtalkEventType) {
  if (!event) {
    return "UNKNOWN"
  }
  // XXX: camelCase Converter에 의해 변환된 경우 UPPER_SNAKE_CASE로 재변환
  let str = event
  if (event.toUpperCase() !== event) {
    str = str.replace(/([A-Z])/g, "_$1").toUpperCase()
  }

  switch (str) {
    case "DELIVER_START":
      return "배송 시작"
    case "DELIVER_DONE":
      return "배송 완료"
    case "PICK_UP_DONE":
      return "픽업 완료"
    case "REMIND_ACCESS_METHOD":
      return "배송 방법 리마인더"
    case "HOLD_PICK_UP":
      return "픽업 보류"
    case "HOLD_DELIVERY":
      return "배송 보류"
    case "RETURN_PICK_UP_SCHEDULED":
      return "수거 예정 알림"
    case "RETURN_PICK_UP_DONE":
      return "수거 완료"
    default:
      return event
  }
}
export function getAlimtalkEventColor(event: AlimtalkEventType) {
  if (!event) {
    return "UNKNOWN"
  }
  // XXX: camelCase Converter에 의해 변환된 경우 UPPER_SNAKE_CASE로 재변환
  let str = event
  if (event.toUpperCase() !== event) {
    str = str.replace(/([A-Z])/g, "_$1").toUpperCase()
  }

  switch (str) {
    case "DELIVER_START":
      return "#8CCB9B"
    case "DELIVER_DONE":
      return "#007BFF"
    case "PICK_UP_DONE":
      return "#FF8C00"
    case "REMIND_ACCESS_METHOD":
      return "#C8A2C8"
    case "HOLD_PICK_UP":
      return "#9F646F"
    case "HOLD_DELIVERY":
      return "#FF5F50"
    case "RETURN_PICK_UP_SCHEDULED":
      return "#00D0D0"
    case "RETURN_PICK_UP_DONE":
      return "#DD00DD"
    default:
      return event
  }
}

export function getTalkTarget(target: SenderOrReceiver) {
  switch (target) {
    case "SENDER":
      return "보낸이"
    case "RECEIVER":
      return "받는이"
    default:
      return target
  }
}
