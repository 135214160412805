import { Issue, IssueComment } from "@today/api/tracker"

export function getVerboseIssueStateName(issue: Issue) {
  switch (issue.state) {
    case "OPEN":
      if (issue.comments.some((v) => v.source === "FIELD")) {
        return "처리중"
      }
      return "대기중 (현장 미확인)"
    case "COMPLETED":
      return "처리 완료"
    case "CANCELED":
      return "취소 종결"
    case "INCIDENT_DECLARED":
      return "사고 발생"
    case "COMPENSATION_PAID":
      return "배상 처리"
    default:
      return issue.state
  }
}
export function getIssueStateName(state: Issue["state"]) {
  switch (state) {
    case "OPEN":
      return "처리 중"
    case "COMPLETED":
      return "처리 완료"
    case "CANCELED":
      return "취소 종결"
    case "INCIDENT_DECLARED":
      return "사고 확정"
    case "COMPENSATION_PAID":
      return "배상 처리"
    default:
      return state
  }
}

export function getIssueIncidentLoadStateName(
  state: IssueComment["extraPayload"]["incidentLoadState"]
) {
  switch (state) {
    case "BEFORE_SHIPPING":
      return "배송 전"
    case "IN_SHIPPING":
      return "배송 중"
    case "AFTER_DELIVERY":
      return "정상배송 후"
    case "WRONG_DELIVERY":
      return "오배송"
    default:
      return state
  }
}
