import { Driver, Organization, Route } from "../../api/tracker"
import { formatPhoneNumber } from "./formatPhoneNumber"

export interface LastPossessor {
  driver: Driver
  organization: Organization
}

const ROUTE_TYPE_TO_VERBOSE_NAME: Record<Route["type"], string> = {
  WD_GENERAL: "간선",
  LC_GENERAL: "지역",
  LM_PICK_UP: "픽업",
  LM_GENERAL: "라스트마일",
}

export function formatLastPossessor(lastPossessor?: LastPossessor): string {
  if (!lastPossessor) return ""

  if (!lastPossessor.driver) {
    return lastPossessor.organization
      ? `알 수 없음 (${lastPossessor.organization.name})`
      : `알 수 없음`
  }

  const route = lastPossessor.driver.assignedRoute
  const routeInfoText = route
    ? `${ROUTE_TYPE_TO_VERBOSE_NAME[route.type]}, ${route.name.slice(
        0,
        route.name.lastIndexOf("-")
      )}`
    : ""

  return lastPossessor
    ? `${lastPossessor.driver.fullName} (${
        lastPossessor.organization?.name ?? ""
      }, ${routeInfoText}, ${formatPhoneNumber(lastPossessor.driver.phone)})`
    : `-`
}
