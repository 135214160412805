export const MY_BASE_URL = process.env.NEXT_PUBLIC_MY_BASE_URL as string
export const TAKER_BASE_URL = process.env.NEXT_PUBLIC_TAKER_BASE_URL as string
export const TRACKER_BASE_URL = process.env
  .NEXT_PUBLIC_TRACKER_BASE_URL as string
export const TRACER_BASE_URL = process.env.NEXT_PUBLIC_TRACER_BASE_URL as string
export const INVOICE_BASE_URL = process.env
  .NEXT_PUBLIC_INVOICE_BASE_URL as string
export const KEYCLOAK_JWT = process.env.NEXT_PUBLIC_KEYCLOAK_JWT as string
export const authConfig = {
  url: process.env.NEXT_PUBLIC_KEYCLOAK_BASE_URL as string,
  realm: process.env.NEXT_PUBLIC_KEYCLOAK_REALM as string,
  clientId: process.env.NEXT_PUBLIC_KEYCLOAK_CLIENT_ID as string,
}
export const KEYCLOAK_BASE_URL = process.env
  .NEXT_PUBLIC_KEYCLOAK_BASE_URL as string
export const KEYCLOAK_REALM = process.env.NEXT_PUBLIC_KEYCLOAK_REALM as string
export const KEYCLOAK_ADMIN_CLIENT_ID = process.env
  .KEYCLOAK_ADMIN_CLIENT_ID as string
export const KEYCLOAK_ADMIN_SECRET_KEY = process.env
  .KEYCLOAK_ADMIN_SECRET_KEY as string
export const CJ_CLIENT_ID = process.env.NEXT_PUBLIC_CJ_CLIENT_ID as string
export const SERVERLESS_BASE_URL = process.env.NEXT_PUBLIC_SERVERLESS_BASE_URL
export const ENV = process.env.NEXT_PUBLIC_ENV as string
export const SLACK_WEBHOOK_URL = process.env.NEXT_PUBLIC_WEBHOOK_URL as string
export const TOAST_APP_KEY = process.env.NEXT_TOAST_APP_KEY as string
export const TOAST_SECRET_KEY = process.env.NEXT_TOAST_SECRET_KEY as string
export const TOAST_SENDER_KEY = process.env.NEXT_TOAST_SENDER_KEY as string
export const SERVERLESS_CJ_BASE_URL = process.env
  .NEXT_SERVERLESS_CJ_BASE_URL as string
export const SERVERLESS_CJ_AUTH_USER_NAME = process.env
  .NEXT_SERVERLESS_CJ_AUTH_USER_NAME as string
export const SERVERLESS_CJ_AUTH_PASSWORD = process.env
  .NEXT_SERVERLESS_CJ_AUTH_PASSWORD as string
