import { Order } from "@today/api/taker"
import { Load } from "@today/api/tracker"
import { isSenderClient } from "."

export function getClientSideEndUserInfo<T extends Order | Load>(
  order: T
): T["sender"] {
  // sender 와 receiver 중 고객사쪽(주문 접수한 쪽)을 return 한다.
  return isSenderClient(order) ? order.sender : order.receiver
}
