import { Load } from "@today/api/tracker"

export function getLoadStateName(state: Load["state"]) {
  switch (state) {
    case "PENDING_COLLECT":
      return "집화 대기 중"
    case "HOLDING_COLLECT":
      return "집화 보류"
    case "IN_COLLECT":
      return "집화 중"
    case "IN_TRANSPORT":
      return "운송 중"
    case "IN_DELIVERY":
      return "배송 중"
    case "HOLDING_DELIVERY":
      return "배송 보류"
    case "AT_STATION":
      return "정류소에서 대기 중"
    case "DELIVERED":
      return "배송 완료"
    case "QUIT":
      return "배송 중단"
    case "LOST":
      return "분실됨"
    default:
      return state
  }
}
