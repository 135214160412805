import { Load } from "@today/api/tracker"

export function formatDeliveryInfoType(load: Load): string {
  if (!load.deliveryInfo || load.state != "DELIVERED") return ""

  switch (load.deliveryInfo.type) {
    case "FORCE_DELIVERY":
      const extraPayload = load.deliveryInfo.extraPayload

      if (extraPayload && "forceDeliverUser" in extraPayload) {
        return `강제 배송 완료, ${extraPayload.forceDeliverUser}`
      } else {
        return `강제 배송 완료`
      }

    case "FIX_WRONG_DELIVERY":
      return `정정 배송 완료`

    default:
      return `딜리앱`
  }
}
