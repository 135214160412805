import axios, { AxiosInstance } from "axios"
import applyCaseMiddleware from "axios-case-converter"
import { Order } from "../taker"
import { defaultBaseUrl } from "@keycloak/keycloak-admin-client/lib/utils/constants"

export class ServerlessCj {
  client: AxiosInstance

  constructor(baseURL: string, username: string, password: string) {
    this.client = applyCaseMiddleware(
      axios.create({
        baseURL: baseURL,
        auth: {
          username,
          password,
        },
      })
    )
  }

  async createCJOrder(cjInvoiceNum: string): Promise<Order> {
    const { data } = await this.client.post(`/nplus-bot/orders`, {
      clientShippingId: cjInvoiceNum,
    })
    return data
  }
}
