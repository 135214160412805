import { sleepAsync } from "./sleepAsync"

export async function retry<T>(
  func: () => Promise<T>,
  maxCount: number,
  delayMs: number
): Promise<T> {
  try {
    return await func()
  } catch (error) {
    if (maxCount <= 0) {
      return Promise.reject(error)
    }
    await sleepAsync(delayMs)
    return retry(func, maxCount - 1, delayMs)
  }
}
