import { ListTemporaryOrdersResponse } from "@today/api/taker"
import { ListFailedAlimtalksResponse, Organization } from "@today/api/tracker"
import { useAuthentication, useUserInfo } from "@today/auth"
import { Accordion, Panel } from "baseui/accordion"
import { Item as BaseItem, Navigation } from "baseui/side-navigation"
import dayjs from "dayjs"
import { useRouter } from "next/router"
import { Fragment, ReactNode, useMemo } from "react"
import { FiExternalLink } from "react-icons/fi"
import useSWR from "swr"
import { TARGET_CLIENT_IDS } from "../temporary-orders/TemporaryOrdersPage"
import {
  getNavigationSections,
  getOutsourcingOrganizationSection,
} from "./navigation-items"
import { classNames } from "@today/lib"
import { useRecoilValue } from "recoil"
import { showsSideNavigationState } from "../../states/showsSideNavigationState"

export function SideNavigation() {
  const showsSideNavigation = useRecoilValue(showsSideNavigationState)
  const { clientRoles, outsourcingOrganizationId } = useUserInfo()
  const today = dayjs().format("YYYY-MM-DD")
  const { data: organizations } = useSWR<Organization[]>(`/api/organizations`)
  const { data: temporaryOrders } = useSWR<ListTemporaryOrdersResponse>(
    `/api/temporary-orders?fromDate=${today}&toDate=${today}&isFixed=false&clientId=${TARGET_CLIENT_IDS.join(
      ","
    )}&page_size=1`
  )
  const { data: sendFaileds } = useSWR<ListFailedAlimtalksResponse>(
    `/api/alimtalks/failed-alimtalks?filter=resolved=false;fail_date_from=${today};fail_date_to=${today}&page_size=1`
  )
  const hasUnresolvedTemporaryOrder = (temporaryOrders?.totalCount ?? 0) > 0
  const hasUnresolvedSendFailed = (sendFaileds?.totalCount ?? 0) > 0
  const sections = useMemo(() => {
    const sections = getNavigationSections(clientRoles)
    const outsourcingOrganizationSection = getOutsourcingOrganizationSection(
      organizations,
      clientRoles,
      outsourcingOrganizationId
    )

    if (!hasUnresolvedTemporaryOrder && !hasUnresolvedSendFailed)
      return sections.concat(outsourcingOrganizationSection)
    return sections
      .map((section) => ({
        ...section,
        items: section.items.map((item) => {
          let shouldBadge = false

          if (item.url === "/loads/temporary" && hasUnresolvedTemporaryOrder) {
            shouldBadge = true
          } else if (
            item.url === "/alimtalks/send-faileds" &&
            hasUnresolvedSendFailed
          ) {
            shouldBadge = true
          }

          return {
            ...item,
            ...(shouldBadge ? { badge: true } : {}),
          }
        }),
      }))
      .concat(outsourcingOrganizationSection)
  }, [
    hasUnresolvedTemporaryOrder,
    hasUnresolvedSendFailed,
    organizations,
    clientRoles,
    outsourcingOrganizationId,
  ])
  return (
    <div
      className={classNames(
        "flex h-full w-56 flex-col overflow-y-scroll border-r pl-2 pt-2",
        showsSideNavigation ? "block" : "hidden"
      )}
    >
      {sections.map((section, i) => {
        const { title, items, isCollapsible } = section
        const sectionNode = (
          <Section
            key={i}
            items={items.map(
              ({ title, url, externalLink, badge, activeOnSubPath }) => ({
                title: externalLink ? (
                  <div className="flex items-center gap-x-2">
                    {title}
                    <FiExternalLink />
                  </div>
                ) : badge ? (
                  <div className="relative inline-block">
                    {title}
                    <div className="absolute right-[-10px] top-[1px] h-[6px] w-[6px] rounded-[50%] bg-red-500" />
                  </div>
                ) : (
                  title
                ),
                itemId: url,
                activeOnSubPath,
              })
            )}
          />
        )
        if (isCollapsible) {
          return (
            <Collapsible key={i} title={title}>
              {sectionNode}
            </Collapsible>
          )
        } else {
          return (
            <Fragment key={i}>
              <Title>{title}</Title>
              {sectionNode}
            </Fragment>
          )
        }
      })}
    </div>
  )
}

function Title(props: { children: ReactNode }) {
  return <div className="px-5 py-4 font-semibold">{props.children}</div>
}

type Item = BaseItem & {
  activeOnSubPath?: boolean
}

function Section(props: { items: Item[] }) {
  const router = useRouter()
  const { logout } = useAuthentication()
  return (
    <Navigation
      items={props.items}
      activeItemId={router.pathname}
      activePredicate={(item, activeItemId) =>
        (item as Item).activeOnSubPath
          ? item.itemId
            ? activeItemId.startsWith(item.itemId)
            : false
          : item.itemId === activeItemId
      }
      onChange={({ event, item }) => {
        event.preventDefault()
        if (item.itemId === "/logout") {
          logout()
        } else if (item.itemId) {
          router.push(item.itemId)
        }
      }}
    />
  )
}

function Collapsible(props: { title: ReactNode; children: ReactNode }) {
  return (
    <Accordion>
      <Panel
        title={props.title}
        overrides={{
          Header: {
            style: {
              fontWeight: 600,
            },
          },
          PanelContainer: {
            style: {
              borderBottomWidth: 0,
            },
          },
          Content: {
            style: {
              paddingTop: 0,
              paddingBottom: "10px",
              paddingLeft: 0,
              paddingRight: 0,
            },
          },
        }}
      >
        {props.children}
      </Panel>
    </Accordion>
  )
}
