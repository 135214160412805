import { Issue, IssueComment } from "@today/api/tracker"

export function getIssueTypeName(state: Issue["type"]) {
  switch (state) {
    case "WRONG_DELIVERY":
      return "오배송"
    case "LOST":
      return "분실"
    case "DAMAGED":
      return "파손"
    case "UNKINDNESS":
      return "불친절"
    case "NOT_COLLECTED":
      return "미회수"
    case "NOT_RECEIVED":
      return "미수령"
    case "QUIT_REQUEST":
      return "중단 요청"
    case "CHANGE_REQUEST":
      return "변경 요청"
    case "SCAN_MISSING":
      return "스캔 누락"
    case "REASON_MISSING":
      return "사유 누락"
    case "ETC":
      return "기타"
    default:
      return `기타(${state})`
  }
}

export function getIssueIncidentTypeName(
  state: IssueComment["extraPayload"]["incidentType"]
) {
  switch (state) {
    case "LOST":
      return "분실"
    case "DAMAGED":
      return "파손"
    case "DELAYED_DELIVERY":
      return "배송 지연"
    case "WRONG_COLLECT":
      return "오회수"
    case "COMPLAIN_REFUND":
      return "불만 환불"
    case "ETC":
      return "기타 (이슈 내용에 상세 사유 기입)"
    default:
      return `기타(${state})`
  }
}
