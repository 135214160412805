export type TargetTimeType = "target_deliver_time" | "event_time"

export type Resource =
  | "all"
  | "clients"
  | "drivers"
  | "region-sets"
  | "organizations"
  | "addresses"
export type TimeInterval =
  | "target-deliver-time-realtime"
  | "target-deliver-time-past"
  | "event-time-realtime"
  | "event-time-past"

export interface IStats extends IRealTimeStats, IEventCount {}

export function getDefaultStats(
  resource: Resource,
  timeInterval: TimeInterval
): IStats
export function getDefaultStats<T extends object = {}>(
  resource: Resource,
  timeInterval: TimeInterval,
  keys: T
): IStats & T

export function getDefaultStats<T extends object = {}>(
  resource: Resource,
  timeInterval: TimeInterval,
  keys?: T
): IStats & T {
  return {
    ...(keys! ?? {}),
    _resource: resource,
    _timeInterval: timeInterval,
    atStationCount: 0,
    deliveredCount: 0,
    deliveredInTimeCount: 0,
    holdCollectCount: 0,
    holdDeliveryCount: 0,
    inCollectCount: 0,
    inDeliveryCount: 0,
    inTransportCount: 0,
    lostCount: 0,
    pendingCollectCount: 0,
    pickUpCount: 0,
    quitCount: 0,
  }
}

export interface IRealTimeStats {
  pendingCollectCount: number
  inCollectCount: number
  inTransportCount: number
  inDeliveryCount: number
  atStationCount: number
}

export interface IEventCount {
  deliveredCount: number
  deliveredInTimeCount: number
  pickUpCount: number
  holdDeliveryCount: number
  holdCollectCount: number
  quitCount: number
  lostCount: number
  readonly _resource: Resource
  readonly _timeInterval: TimeInterval
}

export interface IEventWindow extends IEventCount {
  windowStartTime: string
  windowEndTime: string
}

export type IResourceStats<K extends string> = IStats & {
  [k in K]: string
}

export interface ClientsSummary {
  takingOutClientsCount: number
  takingOutLoadsMinCount: number
  takingOutLoadsMaxCount: number
  takingOutLoadsStandardDeviation: number
  takingOutLoadsTotalCount: number
  collectedLoadsTotalCount: number
  deliveredLoadsTotalCount: number
}
