// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs"

const ENV = process.env.NEXT_PUBLIC_ENV || "dev"
const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://82f05dc3883948bfbfe7294e9c4e5af5@o1048015.ingest.sentry.io/4505056917782528",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: ENV === "prod" ? 1 : 0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  ignoreErrors: [
    "Non-Error exception captured",
    "Non-Error promise rejection captured",
  ],
})
