import {
  EndUserType,
  FailedAlimtalkResolveType,
  ShippingType,
} from "../../common"

export type AlimtalkEventType =
  | "DELIVER_START"
  | "DELIVER_DONE"
  | "PICK_UP_DONE"
  | "REMIND_ACCESS_METHOD"
  | "HOLD_PICK_UP"
  | "HOLD_DELIVERY"
  | "RETURN_PICK_UP_SCHEDULED"
  | "RETURN_PICK_UP_DONE"
  | string

export const ALIMTALK_EVENT_TYPES: AlimtalkEventType[] = [
  "DELIVER_START",
  "DELIVER_DONE",
  "PICK_UP_DONE",
  "REMIND_ACCESS_METHOD",
  "HOLD_PICK_UP",
  "HOLD_DELIVERY",
  "RETURN_PICK_UP_SCHEDULED",
  "RETURN_PICK_UP_DONE",
]

export type SenderOrReceiver = "SENDER" | "RECEIVER"

export type TalkChannel = "TODAY" | "PARTNER"

export const TALK_CHANNELS: TalkChannel[] = ["TODAY", "PARTNER"]

export interface AlimtalkCondition {
  id: string
  name: string
  weight: number
  channel: TalkChannel
  eventType: AlimtalkEventType
  endUserTypes: EndUserType[]
  shippingTypes: ShippingType[]
  extraCondition: string
  // 위는 알림톡 발송 조건에 사용되는 변수입니다.
  // ----------------------------------------
  // 아래는 알림톡 발송에 사용되는 변수입니다.
  send: boolean
  reserveAfter?: string
  templateInfos: AlimtalkTemplateInfo[]
  extraTemplateParameters: { [key: string]: string }
  updateTime: string
}

export interface AlimtalkTemplateInfo {
  target: SenderOrReceiver
  templateCode: string
}

export interface FailedAlimtalk {
  id: string
  loadId: string
  eventType: AlimtalkEventType
  conditionId: string
  templateCode: string
  failReasonResponse: string
  failTime: string
  resolveTime?: string
  resolveType?: FailedAlimtalkResolveType
}

export interface ListFailedAlimtalksResponse {
  failedAlimtalks: FailedAlimtalk[]
  nextPageToken?: string
  totalCount: number
}
