export function toPercent(n: number, fractionDigits: number = 1) {
  return n.toLocaleString("ko-KR", {
    style: "percent",
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  })
}

export function formatDuration(millis: number, sec: boolean = false): string {
  const minutes = Math.floor(millis / 1000 / 60)
  const seconds = millis % 60
  if (minutes > 60) {
    const h = Math.floor(minutes / 60)
    const m = minutes % 60
    return m ? `${h}시간 ${m}분` : `${h}시간`
  } else if (minutes > 0) {
    if (sec) return seconds ? `${minutes}분 ${seconds}초` : `${minutes}분`
    return `${minutes}분`
  } else if (sec) {
    return `${seconds}초`
  } else {
    return "방금"
  }
}
