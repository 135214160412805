import { Taker } from "@today/api/taker"
import { Tracer } from "@today/api/tracer"
import { Tracker } from "@today/api/tracker"
import { KeycloakAdmin } from "@today/api/keycloak"
import { Toast } from "@today/api/toast"
import { ServerlessCj } from "@today/api/serverless-cj"
import {
  KEYCLOAK_ADMIN_CLIENT_ID,
  KEYCLOAK_ADMIN_SECRET_KEY,
  KEYCLOAK_BASE_URL,
  KEYCLOAK_JWT,
  KEYCLOAK_REALM,
  TAKER_BASE_URL,
  TOAST_APP_KEY,
  TOAST_SECRET_KEY,
  TOAST_SENDER_KEY,
  TRACER_BASE_URL,
  TRACKER_BASE_URL,
  SERVERLESS_CJ_BASE_URL,
  SERVERLESS_CJ_AUTH_USER_NAME,
  SERVERLESS_CJ_AUTH_PASSWORD,
} from "../config"

export const taker = new Taker(TAKER_BASE_URL, KEYCLOAK_JWT)
export const tracker = new Tracker(TRACKER_BASE_URL, KEYCLOAK_JWT)
export const tracer = new Tracer(TRACER_BASE_URL, KEYCLOAK_JWT)
export const toast = new Toast(
  TOAST_APP_KEY,
  TOAST_SECRET_KEY,
  TOAST_SENDER_KEY
)
export const keycloakAdmin = new KeycloakAdmin(
  KEYCLOAK_BASE_URL,
  KEYCLOAK_REALM,
  KEYCLOAK_ADMIN_CLIENT_ID,
  KEYCLOAK_ADMIN_SECRET_KEY
)

export const serverlessCj = new ServerlessCj(
  SERVERLESS_CJ_BASE_URL,
  SERVERLESS_CJ_AUTH_USER_NAME,
  SERVERLESS_CJ_AUTH_PASSWORD
)
