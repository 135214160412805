import { Modal, ModalBody, ModalHeader, ModalProps } from "baseui/modal"
import { useTakerAuth } from "../../utils"
import { Input } from "baseui/input"
import { useCallback, useState } from "react"
import { FormControl } from "baseui/form-control"
import { Button } from "baseui/button"
import { CleanAddressResponse } from "@today/api/taker"
import { getErrorMessage } from "@today/api/utils"

type AddressSearchModalProps = ModalProps & {
  handleResult?: (result: CleanAddressResponse) => void
}

export function AddressCleaningModal({ ...props }: AddressSearchModalProps) {
  const taker = useTakerAuth()
  const [address, setAddress] = useState("")
  const [postalCode, setPostalCode] = useState("")
  const [isLoading, setLoading] = useState(false)

  const [result, setResult] = useState<CleanAddressResponse>()
  const [errorMessage, setErrorMessage] = useState("")

  const cleanAddress = useCallback(() => {
    ;(async () => {
      if (!address) return
      setLoading(true)
      setResult(undefined)
      try {
        const resp = await taker.cleanAddress(address, postalCode || undefined)
        setResult(resp)
        setErrorMessage("")
      } catch (err) {
        setResult(undefined)
        setErrorMessage(getErrorMessage(err))
      } finally {
        setLoading(false)
      }
    })()
  }, [address, postalCode, taker])

  const closeModal = () => {
    if (props.handleResult && result) {
      props.handleResult(result)
    }
    setAddress("")
    setPostalCode("")
    setLoading(false)
    setResult(undefined)
    setErrorMessage("")
  }

  return (
    <Modal
      {...props}
      onClose={(closeSource) => {
        props.onClose?.(closeSource)
        closeModal()
      }}
      closeable={!isLoading}
    >
      <ModalHeader>주소 정제 시뮬레이터</ModalHeader>
      <ModalBody className="flex flex-col">
        <form
          onSubmit={(event) => {
            event.preventDefault()
            cleanAddress()
          }}
        >
          <FormControl label="주소">
            <Input
              disabled={isLoading}
              value={address}
              onChange={(event) => setAddress(event.target.value)}
            />
          </FormControl>
          <FormControl label="우편번호 (영문 주소의 경우 필수)">
            <Input
              disabled={isLoading}
              value={postalCode}
              onChange={(event) => setPostalCode(event.target.value)}
            />
          </FormControl>
          <div className="flex flex-row-reverse items-center justify-between">
            <Button
              kind="primary"
              type="submit"
              size="compact"
              className="bg-black"
              isLoading={isLoading}
              disabled={isLoading}
            >
              정제하기
            </Button>
            <div className="text-red-500">{errorMessage}</div>
          </div>
        </form>
        <hr className="my-3" />
        <div className="flex flex-row gap-x-3">
          <p className="w-24 font-bold">우편번호</p>
          <p>{result?.address.postalCode ?? "-"}</p>
        </div>
        <div className="mt-2 flex flex-row gap-x-3">
          <p className="w-24 font-bold">지번 기본주소</p>
          <p>{result?.address.regionBaseAddress ?? "-"}</p>
        </div>
        <div className="flex flex-row gap-x-3">
          <p className="w-24 font-bold">지번 상세주소</p>
          <p>{result?.address.regionDetailAddress ?? "-"}</p>
        </div>
        <div className="mt-2 flex flex-row gap-x-3">
          <p className="w-24 font-bold">도로명 기본주소</p>
          <p>{result?.address.streetBaseAddress ?? "-"}</p>
        </div>
        <div className="flex flex-row gap-x-3">
          <p className="w-24 font-bold">도로명 상세주소</p>
          <p>{result?.address.streetDetailAddress ?? "-"}</p>
        </div>
        <div className="mt-2 flex flex-row gap-x-3">
          <p className="w-24 font-bold">건물명</p>
          <p>{result?.address.buildingName ?? "-"}</p>
        </div>
        <div className="flex flex-row gap-x-3">
          <p className="w-24 font-bold">건물 번호</p>
          <p>{result?.address.buildingNumber ?? "-"}</p>
        </div>
        <div className="flex flex-row gap-x-3">
          <p className="w-24 font-bold">건물고유ID</p>
          <p>{result?.address.buildingId ?? "-"}</p>
        </div>
        <div className="mt-2 flex flex-row gap-x-3">
          <p className="w-24 font-bold">위도</p>
          <p>{result?.location.latitude ?? "-"}</p>
        </div>
        <div className="flex flex-row gap-x-3">
          <p className="w-24 font-bold">경도</p>
          <p>{result?.location.longitude ?? "-"}</p>
        </div>
        {props.handleResult && (
          <div className="mt-4">
            <Button
              kind="primary"
              type="submit"
              size="compact"
              className="bg-black"
              isLoading={isLoading}
              disabled={isLoading}
              onClick={() => {
                props!.onClose!({ closeSource: "closeButton" })
                closeModal()
              }}
            >
              정제 결과 사용
            </Button>
          </div>
        )}
      </ModalBody>
    </Modal>
  )
}
