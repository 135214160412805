export const ROLE = {
  admin: "admin",
  cjDashboardViewer: "dashboard:viewer:cj",
  // dashboardViewer 는 Composite Role 이며, dashboardViewer 는 cjDashboardViewer 를 포함한다.
  dashboardViewer: "dashboard:viewer",
  analyticsViewer: "analytics:viewer",
  // analyticsViewer 는 Composite Role 이며, analyticsViewer 는 operationAnalyticsViewer 를 포함한다.
  operationAnalyticsViewer: "analytics:viewer:operation",
  clientViewer: "client:viewer",
  clientEditor: "client:editor",
  driverList: "driver:list",
  driverEditor: "driver:editor",
  truckEditor: "truck:editor",
  loadList: "load:list",
  loadSearch: "load:search",
  loadEditor: "load:editor",
  cartList: "cart:list",
  cartEditor: "cart:editor",
  issueList: "issue:list",
  issueEditor: "issue:editor",
  // loadEditor 는 Composite Role 이며, loadEditor 는 loadBuildingNoteEditor 를 포함한다.
  loadBuildingNoteEditor: "load:buildingNoteEditor",
  routeViewer: "route:viewer",
  routeEditor: "route:editor",
  hangangViewer: "hangang:viewer",
  seonyeongViewer: "seonyeong:viewer",
  seowonViewer: "seowon:viewer",
  kdlogisticsViewer: "kdlogistics:viewer",
  // 각각의 Group을 특정하기 위해 아래의 권한을 사용한다.
  todayCSHeadOffice: "today:cs:headOffice",
  todayCS: "today:cs",
  todayMaster: "today:master",
  outsourcingMaster: "outsourcing:master",
  alimtalkViewer: "alimtalk:viewer",
  alimtalkEditor: "alimtalk:editor",
  today: "today",
}
