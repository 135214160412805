import { Badge } from "baseui/badge"
import { Button } from "baseui/button"
import { DatePicker } from "baseui/datepicker"
import ko from "date-fns/locale/ko"
import dayjs from "dayjs"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { TemporaryOrderTable } from "./TemporaryOrderTable"
import { ListTemporaryOrdersResponse } from "@today/api/taker"
import { Checkbox } from "baseui/checkbox"
import { AddressCleaningModal } from "./AddressCleaningModal"
import { TemporaryOrderDetailDrawer } from "./TemporaryOrderDetailDrawer"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "baseui/modal"
import { FormControl } from "baseui/form-control"

import { Textarea } from "baseui/textarea"
import axios from "axios"
import { toaster } from "baseui/toast"
import { usePagination } from "@today/lib/hooks"
import { Input } from "baseui/input"

const PAGE_SIZE = 100

export const TARGET_CLIENT_IDS = [
  "110201815933", // CJ대한통운
  "108472190524", // 롯데홈쇼핑
  "122741676407", // 롯데 택배
]

export function TemporaryOrdersPage() {
  const router = useRouter()
  const formatDate = (date: Date) => dayjs(date).format("YYYY-MM-DD")
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [startDateStr, endDateStr] = [
    formatDate(startDate),
    formatDate(endDate),
  ]
  const [isUnfixed, setUnfixed] = useState(true)
  const [filterFake, setFilterFake] = useState(true)
  const [showAddressCleaningModal, setShowAddressCleaningModal] =
    useState(false)
  const [showLotteLogisticsModal, setShowLotteLogisticsModal] = useState(false)
  const [lotteLogisticsInvoiceNumbers, setLotteLogisticsInvoiceNumbers] =
    useState<string>("")
  const [
    processedLotteLogisticsInvoiceNumbers,
    setProcessedLotteLogisticsInvoiceNumbers,
  ] = useState<
    {
      invoiceNumber: string
      success: boolean
      errorMessage?: string
    }[]
  >([])
  const [searchInput, setSearchInput] = useState("")

  const { data: ordersData, mutate } =
    usePagination<ListTemporaryOrdersResponse>(
      `/api/temporary-orders?fromDate=${startDateStr}&toDate=${endDateStr}&clientId=${TARGET_CLIENT_IDS.join(
        ","
      )}${isUnfixed ? "&isFixed=false" : ""}`,
      PAGE_SIZE,
      true
    )

  useEffect(() => {
    mutate()
  }, [startDate, endDate, isUnfixed])

  const temporaryOrders = ordersData
    ?.flatMap((resp) => resp.temporaryOrders)
    .filter((order) => {
      if (filterFake) {
        if (order.clientId === "108472190524" && order.fixedTime === null) {
          return false
        }
      }
      if (!searchInput) {
        return true
      }
      return (
        order.invoiceNumber.includes(searchInput) ||
        order.clientShippingId.includes(searchInput) ||
        order.forwardingInfo?.invoiceNumber.includes(searchInput) ||
        order.receiver.name.includes(searchInput) ||
        order.receiver.phone.includes(searchInput)
      )
    })
  const isLoading = !temporaryOrders

  const createLotteLogisticsOrders = async () => {
    const invoiceNumbers = lotteLogisticsInvoiceNumbers
      .split("\n")
      .map((invoiceNumber) => invoiceNumber.trim().replace(/-/g, ""))
    for (const invoiceNumber of invoiceNumbers) {
      try {
        await axios.post(`/api/lotte-logistics-orders`, {
          invoiceNumber,
        })
        setProcessedLotteLogisticsInvoiceNumbers((prev) => [
          ...prev,
          {
            invoiceNumber,
            success: true,
          },
        ])
      } catch (err: Error | any) {
        setProcessedLotteLogisticsInvoiceNumbers((prev) => [
          ...prev,
          {
            invoiceNumber,
            success: false,
            errorMessage:
              {
                E20208: "동일 주문 존재",
                E20901: "주소 정제 실패",
                E20902: "주소 정제 결과와 우편번호 불일치",
                E20500: "서비스 구역 활성화 상태 아님",
                E00400: "요청 페이로드 오류",
              }[err?.response?.data?.errorCode as string] ||
              err?.response?.data?.errorMessage,
          },
        ])
        toaster.negative(
          `송장번호 ${invoiceNumber}를 등록하는데 실패했습니다.: ${err?.response?.data?.errorMessage}`
        )
      }
    }
    toaster.positive(
      `등록 완료 (성공 : ${
        processedLotteLogisticsInvoiceNumbers.length
      }개, 실패 : ${
        invoiceNumbers.length - processedLotteLogisticsInvoiceNumbers.length
      }개)`
    )
  }

  return (
    <div className="flex h-full flex-col">
      <div className="flex items-center gap-x-2 p-2">
        <div className="w-64">
          <DatePicker
            range
            locale={ko}
            value={[startDate, endDate]}
            onChange={({ date }) => {
              const [d1, d2] = date as Date[]
              setStartDate(d1)
              setEndDate(d2)
            }}
            monthsShown={2}
          />
        </div>
        <Badge content={`총 ${temporaryOrders?.length ?? "-"}개`} />
        <div className="w-full md:w-[500px]">
          <Input
            placeholder="송장번호 / 고객명 / 전화번호 / 위탁사 송장번호"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </div>
        <Checkbox
          checked={isUnfixed}
          onChange={(event) => setUnfixed(event.target.checked)}
        >
          <span className="ml-[-0.2rem] text-sm font-light">
            미해결 물품들만
          </span>
        </Checkbox>
        <Checkbox
          checked={filterFake}
          onChange={(event) => setFilterFake(event.target.checked)}
        >
          <span className="ml-[-0.2rem] text-sm font-light">
            허주문 의심 물품 제외
          </span>
        </Checkbox>
        <div className="flex-1" />
        <Button
          size="compact"
          className="mx-0.5"
          onClick={() => {
            setShowLotteLogisticsModal(true)
          }}
        >
          롯데 택배 미등록 송장 접수
        </Button>
        <Button
          size="compact"
          className="mx-0.5"
          onClick={() => {
            setShowAddressCleaningModal(true)
          }}
        >
          주소 정제 시뮬레이터
        </Button>
      </div>
      <div className="flex-1 overflow-y-scroll p-2 pb-0">
        <TemporaryOrderTable orders={temporaryOrders} isLoading={isLoading} />
      </div>
      <TemporaryOrderDetailDrawer
        key={router.query.orderId as string}
        orderId={router.query.orderId as string}
        onClose={() => {
          mutate()
          router.back()
        }}
      />
      <AddressCleaningModal
        overrides={{
          Dialog: { style: { width: "60%" } },
        }}
        isOpen={showAddressCleaningModal}
        onClose={() => {
          setShowAddressCleaningModal(false)
        }}
      />
      <Modal
        isOpen={showLotteLogisticsModal}
        onClose={() => {
          setLotteLogisticsInvoiceNumbers("")
          setProcessedLotteLogisticsInvoiceNumbers([])
          setShowLotteLogisticsModal(false)
        }}
      >
        <ModalHeader>롯데 택배 미등록 송장 접수</ModalHeader>
        <ModalBody>
          <FormControl label="송장 번호 리스트">
            <Textarea
              value={lotteLogisticsInvoiceNumbers}
              onChange={(event) =>
                setLotteLogisticsInvoiceNumbers(event.currentTarget.value)
              }
              placeholder="개행으로 구분된 송장 번호 목록을 입력해주세요."
            ></Textarea>
          </FormControl>
          <div>
            <h2>송장번호 목록</h2>
            <ul>
              {processedLotteLogisticsInvoiceNumbers.map((invoice) => (
                <li
                  key={invoice.invoiceNumber}
                  className={
                    invoice.success ? "text-green-600" : "text-red-600"
                  }
                >
                  {invoice.invoiceNumber} -{" "}
                  {invoice.success ? "성공" : `실패: ${invoice.errorMessage}`}
                </li>
              ))}
            </ul>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => createLotteLogisticsOrders()}>접수하기</Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
