import { OrgId, OrgIds, Station } from "@today/api/tracker"

export function getStationsOfOrg(orgId: OrgId, stations: Station[]) {
  return stations.filter((s) => {
    switch (orgId) {
      case OrgIds.kdlogistics:
        return s.name.startsWith("K")

      default:
        return true
    }
  })
}
