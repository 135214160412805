import { IssueComment } from "@today/api/tracker"

export function getIssueCommentActionName(state: IssueComment["action"]) {
  switch (state) {
    case "OPEN":
      return "최초 등록"
    case "COMMENT":
      return "경과 기록"
    case "COMPLETE":
      return "완료 종결"
    case "CANCEL":
      return "취소 종결"
    case "DECLARE_INCIDENT":
      return "사고 확정"
    case "PAY_COMPENSATION":
      return "배상 처리"
    case "REOPEN":
      return "재개 등록"
    default:
      return state
  }
}
