export function getRandomClientId(isProd: boolean) {
  let clientId = isProd ? "1" : "5"
  for (let i = 0; i < 11; i++) {
    clientId = `${clientId}${getRandomIntegerString(0, 10)}`
  }
  return clientId
}

function getRandomIntegerString(s: number, e: number) {
  return (Math.floor(Math.random() * (e - s)) + s).toString()
}
