export interface Issue {
  id: string
  loadId: string
  loadInvoiceNumber: string
  loadClientShippingId: string
  loadForwardingInvoiceNumber: string
  loadCustomerName: string
  type: // 추가 이후 ISSUE_TYPES, getIssueTypeName 수정 필요
  | "WRONG_DELIVERY"
    | "LOST"
    | "DAMAGED"
    | "UNKINDNESS"
    | "NOT_COLLECTED"
    | "NOT_RECEIVED"
    | "QUIT_REQUEST"
    | "CHANGE_REQUEST"
    | "SCAN_MISSING"
    | "REASON_MISSING"
    | "ETC"
  state:
    | "OPEN"
    | "INCIDENT_DECLARED"
    | "COMPLETED"
    | "CANCELED"
    | "COMPENSATION_PAID"
  createTime: string
  lastUpdateTime: string
  pickUpTime: string | null
  clientId: string
  stationId: string
  routeName: string
  organizationId: string
  driverName: string
  extraPayload: any
  comments: IssueComment[]
}

export const ISSUE_TYPES: Issue["type"][] = [
  "WRONG_DELIVERY",
  "LOST",
  "DAMAGED",
  "UNKINDNESS",
  "NOT_COLLECTED",
  "NOT_RECEIVED",
  "QUIT_REQUEST",
  "CHANGE_REQUEST",
  "SCAN_MISSING",
  "REASON_MISSING",
  "ETC",
]

export const ISSUE_STATES: Issue["state"][] = [
  "OPEN",
  "COMPLETED",
  "CANCELED",
  "INCIDENT_DECLARED",
  "COMPENSATION_PAID",
]

export type IssueFilterType = string | ((issue: Issue) => boolean)

export interface IssueComment {
  id: string
  createTime: string
  author: string
  action:
    | "OPEN"
    | "COMMENT"
    | "COMPLETE"
    | "CANCEL"
    | "REOPEN"
    | "DECLARE_INCIDENT"
    | "PAY_COMPENSATION" // comment 를 제외하고는 Issue 에 대한 Patch를 할 때 자동으로 만들어진다.
  state: Issue["state"]
  source: "FIELD" | "CS"
  content: string
  username: string
  extraPayload: {
    imageUrls?: string[]
    // 사고 확정 필드
    incidentLoadState?:
      | "BEFORE_SHIPPING"
      | "IN_SHIPPING"
      | "AFTER_DELIVERY"
      | "WRONG_DELIVERY"
    incidentType?:
      | "LOST"
      | "DAMAGED"
      | "DELAYED_DELIVERY"
      | "WRONG_COLLECT"
      | "COMPLAIN_REFUND"
      | "ETC"
    // 배상 처리 필드
    compensationAmount?: number
    compensationShareTime?: string
    compensationPaidTime?: string
  }
}

export const INCIDENT_LOAD_STATES: IssueComment["extraPayload"]["incidentLoadState"][] =
  ["BEFORE_SHIPPING", "IN_SHIPPING", "AFTER_DELIVERY", "WRONG_DELIVERY"]
export const INCIDENT_TYPES: IssueComment["extraPayload"]["incidentType"][] = [
  "LOST",
  "DAMAGED",
  "DELAYED_DELIVERY",
  "WRONG_COLLECT",
  "COMPLAIN_REFUND",
  "ETC",
]
