import axios, { AxiosInstance } from "axios"
import applyCaseMiddleware from "axios-case-converter"
import { ListToastTemplatesResponse, ToastMessage } from "./types"

export class Toast {
  client: AxiosInstance
  senderKey: string

  constructor(appKey: string, secretKey: string, senderKey: string) {
    this.senderKey = senderKey
    this.client = applyCaseMiddleware(
      axios.create({
        baseURL: `https://api-alimtalk.cloud.toast.com/alimtalk/v2.3/appkeys/${appKey}`,
        headers: {
          "X-Secret-Key": secretKey,
        },
      })
    )
  }

  async listMessages(
    queryParams: Record<string, string>
  ): Promise<ToastMessage[]> {
    const paramsString = new URLSearchParams(queryParams)
    const { data } = await this.client.get(`/messages?${paramsString}`)

    return data.messageSearchResultResponse.messages
  }

  async listDetailMessages(
    queryParams: Record<string, string>
  ): Promise<ToastMessage[]> {
    const paramsString = new URLSearchParams(queryParams)
    const { data } = await this.client.get(`/messages?${paramsString}`)

    const messages = data.messageSearchResultResponse.messages

    // XXX: listMessages에서는 templateItem을 반환하지 않기 때문에, 단건 조회 수행
    const detailMessages = await Promise.all(
      messages.map(async (message: ToastMessage) => {
        return await this.getDetailMessage(
          message.requestId,
          message.recipientSeq
        )
      })
    )
    return detailMessages
  }

  async getDetailMessage(
    requestId: string,
    recipientSeq: number
  ): Promise<ToastMessage> {
    const { data } = await this.client.get(
      `/messages/${requestId}/${recipientSeq}`
    )
    return data.message
  }

  async listTemplates(
    queryParams: Record<string, string>
  ): Promise<ListToastTemplatesResponse> {
    const paramsString = new URLSearchParams(queryParams)
    const { data } = await this.client.get(
      `/senders/${this.senderKey}/templates?pageSize=1000${paramsString}`
    )
    return data.templateListResponse.templates
  }
}
