import { useCallback, useEffect, useRef } from "react"

export function useOnCloseOnce<T>(id?: T, onClose?: () => void) {
  const recentClosedID = useRef<T>()
  const onCloseOnce = useCallback(() => {
    if (recentClosedID.current === id) return
    recentClosedID.current = id
    onClose?.()
  }, [id, onClose])
  useEffect(() => {
    recentClosedID.current = undefined
  }, [id])
  return onCloseOnce
}
