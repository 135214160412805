import { Cart } from "@today/api/tracker"

export function getCartStateName(state: Cart["state"]) {
  switch (state) {
    case "IN_SHIPPING":
      return "운송 중"
    case "BEFORE_SORTING":
      return "분류 대기 중"
    case "SORTING_SOURCE":
      return "분류 중"
    case "SORTING_TARGET":
      return "담는 중"
    case "EMPTY":
      return "빈 카트"
    case "AT_PICK_UP_STATION":
      return "픽업 대기 중"
    case "STAND_BY":
      return "운송 대기 중"
    case "LOST":
      return "분실됨"
    default:
      return state
  }
}
