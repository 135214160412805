import axios, { AxiosInstance } from "axios"
import applyCaseMiddleware from "axios-case-converter"
import { LoadStatusDetailEvent, TraceEvent } from "./types"
import { PaginationResponse } from "../utils"

export class Tracer {
  client: AxiosInstance

  constructor(baseUrl: string, token: string) {
    this.client = applyCaseMiddleware(
      axios.create({
        baseURL: baseUrl,
        headers: {
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
        },
      })
    )
  }

  async listLoadStatuses(
    invoiceNumber: string,
    type: "id" | "invoice_number" = "id"
  ): Promise<LoadStatusDetailEvent[]> {
    const { data } = await this.client.get(
      `/trace/v1/loads/${invoiceNumber}/statuses?type=${type}`
    )
    return data.statuses as LoadStatusDetailEvent[]
  }

  async listLoadTraces(
    id: string,
    type: "id" | "invoice_number",
    params: Record<string, string>
  ) {
    const paramsString = new URLSearchParams(params)
    paramsString.set("type", type)
    const { data } = await this.client.get(
      `/trace/v1/loads/${id}/traces?${paramsString}`
    )
    return data as PaginationResponse<"traces", TraceEvent>
  }

  async listTruckTraces(
    id: string,
    type: "id" | "car_number" | "round_id",
    params: Record<string, string>
  ) {
    const paramsString = new URLSearchParams(params)
    paramsString.set("type", type)
    const { data } = await this.client.get(
      `/trace/v1/trucks/${id}/traces?${paramsString}`
    )
    return data as PaginationResponse<"traces", TraceEvent>
  }
}
