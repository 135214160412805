import React, { FC, useContext, useState } from "react"

export interface MapContextType {
  map: naver.maps.Map | null
  setMap(map: naver.maps.Map): void
}

const MapContext = React.createContext<MapContextType>({
  map: null,
  setMap: (map: naver.maps.Map) => {
    // This is intentional
  },
})

/**
 * naver.maps.Map 리턴하는 프로바이더
 * @param map Map
 * @param children
 * @constructor
 */
const MapProvider: FC = ({ children }) => {
  const [map, setMap] = useState<naver.maps.Map | null>(null)
  return (
    <MapContext.Provider value={{ map, setMap }}>
      {children}
    </MapContext.Provider>
  )
}

export { MapProvider, MapContext }

export const useMap = () => useContext(MapContext)
