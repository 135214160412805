const HUMAN_FRIENDLY_IDENTIFIER_WORDS_1 = [
  "가깝고",
  "가늘고",
  "가능하고",
  "가득하고",
  "가볍고",
  "간단하고",
  "간편하고",
  "감사하고",
  "강력하고",
  "강하고",
  "거대하고",
  "거칠고",
  "건강하고",
  "건전하고",
  "건조하고",
  "검고",
  "게으르고",
  "고맙고",
  "고소하고",
  "고요하고",
  "곱고",
  "굉장하고",
  "궁금하고",
  "귀엽고",
  "귀하고",
  "그립고",
  "급하고",
  "기막히고",
  "기쁘고",
  "길고",
  "깊고",
  "까맣고",
  "깔끔하고",
  "깨끗하고",
  "꼼꼼하고",
  "꾸준하고",
  "날카롭고",
  "낮고",
  "낯설고",
  "넉넉하고",
  "넓고",
  "노랗고",
  "놀랍고",
  "높고",
  "눈부시고",
  "다르고",
  "다양하고",
  "다정하고",
  "단단하고",
  "단순하고",
  "달고",
  "당당하고",
  "당연하고",
  "대단하고",
  "덥고",
  "독특하고",
  "독하고",
  "동일하고",
  "두껍고",
  "두렵고",
  "둥글고",
  "드물고",
  "따뜻하고",
  "따스하고",
  "딱딱하고",
  "똑똑하고",
  "뛰어나고",
  "뜨겁고",
  "많고",
  "맑고",
  "맛없고",
  "맛있고",
  "맵고",
  "멀고",
  "멋있고",
  "명확하고",
  "못생기고",
  "무겁고",
  "무덥고",
  "무사하고",
  "무섭고",
  "미끄럽고",
  "미안하고",
  "밀접하고",
  "밉고",
  "바르고",
  "바쁘고",
  "반갑고",
  "밝고",
  "밤늦고",
  "배고프고",
  "배부르고",
  "별다르고",
  "복잡하고",
  "부드럽고",
  "부럽고",
  "부족하고",
  "분명하고",
  "분주하고",
  "불행하고",
  "붉고",
  "비슷하고",
  "비싸고",
  "빠르고",
  "빨갛고",
  "뻔하고",
  "사소하고",
  "사이좋고",
  "상당하고",
  "상쾌하고",
  "새롭고",
  "색다르고",
  "서늘하고",
  "선명하고",
  "성실하고",
  "세고",
  "세련되고",
  "소박하고",
  "소중하고",
  "손쉽고",
  "솔직하고",
  "순수하고",
  "순진하고",
  "순하고",
  "쉽고",
  "슬프고",
  "시끄럽고",
  "시원하고",
  "신기하고",
  "신선하고",
  "신속하고",
  "신중하고",
  "심각하고",
  "심심하고",
  "심하고",
  "싱겁고",
  "싱싱하고",
  "싸고",
  "썰렁하고",
  "쓰고",
  "쓸쓸하고",
  "씩씩하고",
  "아깝고",
  "아름답고",
  "아쉽고",
  "안전하고",
  "약하고",
  "얄밉고",
  "얇고",
  "얕고",
  "어둡고",
  "어렵고",
  "어리고",
  "어색하고",
  "어지럽고",
  "억울하고",
  "엄격하고",
  "엄숙하고",
  "엄청나고",
  "없고",
  "엉뚱하고",
  "여전하고",
  "연하고",
  "엷고",
  "영원하고",
  "예쁘고",
  "올바르고",
  "옳고",
  "완벽하고",
  "완전하고",
  "외롭고",
  "용감하고",
  "우수하고",
  "우아하고",
  "우울하고",
  "위대하고",
  "위험하고",
  "유능하고",
  "유리하고",
  "유명하고",
  "은은하고",
  "이롭고",
  "이르고",
  "이상하고",
  "익숙하고",
  "자세하고",
  "자유롭고",
  "작고",
  "잘나고",
  "잘생기고",
  "재미없고",
  "재미있고",
  "적고",
  "적당하고",
  "적절하고",
  "젋고",
  "점잖고",
  "정직하고",
  "정확하고",
  "조그맣고",
  "조용하고",
  "좁고",
  "좋고",
  "중대하고",
  "중요하고",
  "즐겁고",
  "지겹고",
  "지나치고",
  "지루하고",
  "진실하고",
  "진지하고",
  "진하고",
  "짙고",
  "짜고",
  "짧고",
  "차갑고",
  "착하고",
  "참되고",
  "철저하고",
  "초조하고",
  "촌스럽고",
  "춥고",
  "충분하고",
  "친절하고",
  "친하고",
  "침착하고",
  "캄캄하고",
  "커다랗고",
  "크고",
  "탁월하고",
  "투명하고",
  "특별하고",
  "특이하고",
  "튼튼하고",
  "파랗고",
  "편리하고",
  "평범하고",
  "평화롭고",
  "포근하고",
  "폭넓고",
  "푸르고",
  "풍부하고",
  "필요하고",
  "하얗고",
  "한가하고",
  "행복하고",
  "화려하고",
  "확실하고",
  "환하고",
  "활발하고",
  "훌륭하고",
  "흐리고",
  "흔하고",
  "흥미롭고",
  "희고",
  "힘차고",
]

const HUMAN_FRIENDLY_IDENTIFIER_WORDS_2 = [
  "가까운",
  "가는",
  "가능한",
  "가득한",
  "가벼운",
  "간단한",
  "간편한",
  "감사한",
  "강력한",
  "강한",
  "거대한",
  "거친",
  "건강한",
  "건전한",
  "건조한",
  "검은",
  "게으른",
  "고마운",
  "고소한",
  "고요한",
  "고운",
  "굉장한",
  "궁금한",
  "귀여운",
  "귀한",
  "그리운",
  "급한",
  "기막힌",
  "기쁜",
  "긴",
  "깊은",
  "까만",
  "깔끔한",
  "깨끗한",
  "꼼꼼한",
  "꾸준한",
  "날카로운",
  "낮은",
  "낯설은",
  "넉넉한",
  "넓은",
  "노란",
  "놀라운",
  "높은",
  "눈부신",
  "다른",
  "다양한",
  "다정한",
  "단단한",
  "단순한",
  "단",
  "당당한",
  "당연한",
  "대단한",
  "더운",
  "독특한",
  "독한",
  "동일한",
  "두꺼운",
  "두려운",
  "둥근",
  "드문",
  "따뜻한",
  "따스한",
  "딱딱한",
  "똑똑한",
  "뛰어난",
  "뜨거운",
  "많은",
  "맑은",
  "맛없는",
  "맛있는",
  "매운",
  "먼",
  "멋있는",
  "명확한",
  "못생긴",
  "무거운",
  "무더운",
  "무사한",
  "무서운",
  "미끄러운",
  "미안한",
  "밀접한",
  "미운",
  "바른",
  "바쁜",
  "반가운",
  "밝은",
  "밤늦은",
  "배고픈",
  "배부른",
  "별다른",
  "복잡한",
  "부드러운",
  "부러운",
  "부족한",
  "분명한",
  "분주한",
  "불행한",
  "붉은",
  "비슷한",
  "비싼",
  "빠른",
  "빨간",
  "뻔한",
  "사소한",
  "사이좋은",
  "상당한",
  "상쾌한",
  "새로운",
  "색다른",
  "서늘한",
  "선명한",
  "성실한",
  "센",
  "세련된",
  "소박한",
  "소중한",
  "손쉬운",
  "솔직한",
  "순수한",
  "순진한",
  "순한",
  "쉬운",
  "슬픈",
  "시끄러운",
  "시원한",
  "신기한",
  "신선한",
  "신속한",
  "신중한",
  "심각한",
  "심심한",
  "심한",
  "싱거운",
  "싱싱한",
  "싼",
  "썰렁한",
  "쓴",
  "쓸쓸한",
  "씩씩한",
  "아까운",
  "아름다운",
  "아쉬운",
  "안전한",
  "약한",
  "얄미운",
  "얇은",
  "얕은",
  "어두운",
  "어려운",
  "어린",
  "어색한",
  "어지러운",
  "억울한",
  "엄격한",
  "엄숙한",
  "엄청난",
  "없는",
  "엉뚱한",
  "여전한",
  "연한",
  "엷은",
  "영원한",
  "예쁜",
  "올바른",
  "옳은",
  "완벽한",
  "완전한",
  "외로운",
  "용감한",
  "우수한",
  "우아한",
  "우울한",
  "위대한",
  "위험한",
  "유능한",
  "유리한",
  "유명한",
  "은은한",
  "이로운",
  "이른",
  "이상한",
  "익숙한",
  "자세한",
  "자유로운",
  "작은",
  "잘난",
  "잘생긴",
  "재미없는",
  "재미있는",
  "적은",
  "적당한",
  "적절한",
  "젊은",
  "점잖은",
  "정직한",
  "정확한",
  "조그만",
  "조용한",
  "좁은",
  "좋은",
  "종대한",
  "중요한",
  "즐거운",
  "지겨운",
  "지나친",
  "지루한",
  "진실된",
  "진지한",
  "진한",
  "짙은",
  "짠",
  "짧은",
  "차가운",
  "착한",
  "참된",
  "철저한",
  "초조한",
  "촌스러운",
  "추운",
  "충분한",
  "친절한",
  "친한",
  "침착한",
  "캄캄한",
  "커다란",
  "큰",
  "탁월한",
  "투명한",
  "특별한",
  "특이한",
  "튼튼한",
  "파란",
  "편리한",
  "평범한",
  "평화로운",
  "포근한",
  "폭넓은",
  "푸른",
  "풍부한",
  "필요한",
  "하얀",
  "한가한",
  "행복한",
  "화려한",
  "확실한",
  "환한",
  "활발한",
  "훌륭한",
  "흐린",
  "흔한",
  "흥미로운",
  "흰",
  "힘찬",
]

const HUMAN_FRIENDLY_IDENTIFIER_WORDS_3 = [
  "개",
  "곰",
  "굴",
  "꿩",
  "닭",
  "말",
  "매",
  "뱀",
  "벌",
  "삵",
  "새",
  "소",
  "양",
  "쥐",
  "가재",
  "개미",
  "거미",
  "거북",
  "거위",
  "고니",
  "고래",
  "곰쥐",
  "공작",
  "기린",
  "까치",
  "꿀벌",
  "나방",
  "나비",
  "낙지",
  "낙타",
  "노루",
  "누에",
  "늑대",
  "담비",
  "돼지",
  "뒤쥐",
  "딱새",
  "라마",
  "말벌",
  "매미",
  "메기",
  "멧닭",
  "멧새",
  "무스",
  "문어",
  "물개",
  "물닭",
  "물쥐",
  "밍크",
  "박새",
  "박쥐",
  "밭쥐",
  "배스",
  "백로",
  "백조",
  "벌새",
  "불곰",
  "불독",
  "붕어",
  "비버",
  "뿔닭",
  "사슴",
  "사자",
  "산양",
  "상어",
  "새매",
  "새우",
  "생쥐",
  "성게",
  "소새",
  "솔개",
  "솔새",
  "송어",
  "수달",
  "수리",
  "순록",
  "쉬리",
  "아귀",
  "악어",
  "야크",
  "어치",
  "여새",
  "여우",
  "연어",
  "염소",
  "영원",
  "오리",
  "왕뱀",
  "원앙",
  "웜뱃",
  "잉어",
  "자칼",
  "전갈",
  "전복",
  "전어",
  "제비",
  "참돔",
  "참매",
  "참새",
  "치타",
  "키위",
  "타조",
  "토끼",
  "파리",
  "팬더",
  "펭귄",
  "표범",
  "퓨마",
  "하마",
  "해삼",
  "호저",
  "홍학",
  "홍합",
  "황로",
  "황새",
  "흰매",
  "가리비",
  "가마새",
  "가물치",
  "갈매기",
  "강돼지",
  "강아지",
  "강준치",
  "개개비",
  "개구리",
  "고라니",
  "고릴라",
  "고양이",
  "구관조",
  "구렁이",
  "군함조",
  "굴뚝새",
  "금붕어",
  "기러기",
  "까마귀",
  "꾀꼬리",
  "남생이",
  "너구리",
  "늘보곰",
  "다람쥐",
  "다슬기",
  "달팽이",
  "당나귀",
  "도롱뇽",
  "도마뱀",
  "도요새",
  "독수리",
  "돌고래",
  "동박새",
  "동사리",
  "돛새치",
  "두꺼비",
  "두더지",
  "두루미",
  "따개비",
  "따오기",
  "뜸부기",
  "마도요",
  "말미잘",
  "망둥어",
  "맹꽁이",
  "먹황새",
  "메뚜기",
  "멧돼지",
  "몽구스",
  "물방개",
  "물수리",
  "물총새",
  "미어캣",
  "반달곰",
  "방울뱀",
  "방울새",
  "백상어",
  "뱀장어",
  "버들치",
  "범고래",
  "범상어",
  "베짱이",
  "병아리",
  "보아뱀",
  "북극곰",
  "블루길",
  "비단뱀",
  "비둘기",
  "뻐꾸기",
  "사마귀",
  "사향소",
  "산천어",
  "산호뱀",
  "살모사",
  "삽살개",
  "상딱새",
  "셰퍼드",
  "소라게",
  "소쩍새",
  "송골매",
  "스컹크",
  "쏘가리",
  "악어새",
  "알파카",
  "앵무새",
  "어름치",
  "얼룩말",
  "열목어",
  "오색조",
  "오소리",
  "오징어",
  "올빼미",
  "올챙이",
  "왜가리",
  "우렁이",
  "원숭이",
  "유니콘",
  "인드리",
  "임팔라",
  "잎벌레",
  "자고새",
  "잠자리",
  "재규어",
  "저어새",
  "조랑말",
  "족제비",
  "좀도요",
  "종다리",
  "지렁이",
  "진돗개",
  "진드기",
  "참수리",
  "천인조",
  "청새치",
  "청설모",
  "초파리",
  "친칠라",
  "칠면조",
  "침팬지",
  "캥거루",
  "코끼리",
  "코브라",
  "코뿔소",
  "코알라",
  "코요테",
  "콘도르",
  "투구게",
  "파랑새",
  "팔색조",
  "풍금새",
  "피라냐",
  "피라미",
  "하늘소",
  "할미새",
  "해파리",
  "호금조",
  "호랑이",
  "호반새",
  "호아친",
  "황여새",
]

export const toHumanFriendlyId = (uuid: string) => {
  const word_1 =
    HUMAN_FRIENDLY_IDENTIFIER_WORDS_1[Number(`0x${uuid.slice(0, 2)}`)]
  const word_2 =
    HUMAN_FRIENDLY_IDENTIFIER_WORDS_2[Number(`0x${uuid.slice(2, 4)}`)]
  const word_3 =
    HUMAN_FRIENDLY_IDENTIFIER_WORDS_3[Number(`0x${uuid.slice(4, 6)}`)]

  return `${word_1} ${word_2} ${word_3}`
}
